<template>
    <div class="wrapper is-vertical is-fullpage is-vcentered">
        <div class="button is-danger" @click="logout">
            Logout
        </div>
    </div>
    <Navbar class="is-fixed-bottom" @buttonClicked="handleEditClick" />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "@/components/Navbar.vue";

export default {
    name: "User",
    components: {
        Navbar
    },
    data: function() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            page: "user/getPage"
        }),
        profilePic: function() {
            if(!this.page || !this.user) return null;
            if(this.page && this.page.profile_pic) return this.page.profile_pic;
            if(this.user && this.user.profile_pic) return this.user.profile_pic;
            return null;
        },
        profilePicBG: function() {
            var pic = this.profilePic ? this.profilePic.url : null;
            if(!pic) pic = this.gravatar;
            return { backgroundImage: `url(${pic})`};
        },
    },
    methods: {
        ...mapActions({
            doLogout: "auth/doLogout",
            setBackground: "layout/setBackground"
        }),
        logout: function() {
            this.doLogout()
            .then(() => {
                this.$router.push({ name: "login"});
            })
            .catch(err => {
                console.error(err);
            })
        },
    },
    mounted: function() {
        this.setBackground({ "background.blurred": false });
    }
}
</script>